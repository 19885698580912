import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormControl,
  // FormGroup,
  Validators,
  FormGroupDirective,
  NgForm
} from '@angular/forms';
// import { FormGroup, FormBuilder } from '@angular/forms';

import {
  ErrorStateMatcher
} from '@angular/material/core';

import {
  ContactService
} from './contact.service';
import {
  Contact
} from './models/contact.model';
// import { SharedModule } from '@app/shared';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
// import { Route } from '../../../../node_modules/@angular/compiler/src/core';

@Component({
  selector: 'sidTrust-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [ContactService]
  // This is just for styling and will add the class "row" to the parent-tag
  // host: { 'class': 'row' }
})
export class ContactComponent implements OnInit {
  contact: Contact = new Contact();
  loading = true;
  // contactForm: FormGroup;
  returnUrl: string;
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private contactService: ContactService) {}

  ngOnInit() {
    this.loading = false;
  }

  onSubmit() {
    this.loading = true;
    // Don't forget to subscribe on an observable, or it will never be called.
    this.contactService.sendMail(this.contact).subscribe(
      () => {
        // Success
        this.contact = new Contact();
        this.loading = false;
        // alert('Message sent.');
        this.toastr.success('Message successfully sent.');
        this.router.navigate(['/about']);
      },
      () => {
        // Failed
        this.loading = false;
        // alert(
        //   'An error occured. Please try again or send email to trust@sunriseid.com.'
        // );
        this.toastr.error('Something went wrong. Please try again.');
      }
    );
  }
}

export class InputErrorStateMatcherExample {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);

  matcher = new MyErrorStateMatcher();
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'sidTrust-contact',
//   templateUrl: './contact.component.html',
//   styleUrls: ['./contact.component.scss']
// })
// export class ContactComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }
